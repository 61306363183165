import enquire from 'enquire.js'
import Swiper from 'swiper'

var slides = document.querySelectorAll('.gallery .swiper-slide')
var length = slides.length
var counter = 0
var swiper = null
var attached = false
var loaded = false

var srcsetSupport = typeof document.createElement('img').srcset !== 'undefined'

function checkStatus() {
    if (slides.length === counter) {
        loaded = true
    }

    if (attached && loaded) {
        swiper = new Swiper('.gallery .swiper-container', {
            //initialSlide: Math.round(slides.length/2),
            preloadImages: false,
            lazyLoading: true,
            slidesPerView: 'auto',
            centeredSlides: true,
            spaceBetween: 120,
            loop: true,
            autoplay: 3000,
        })
    }
}

function setup() {
    Array.prototype.forEach.call(slides, function (slide) {
        if (slide.dataset.src) {
            var image = new Image()
            image.onload = function () {
                counter++
                slide.appendChild(image)
                checkStatus()
            }

            image.onerror = function () {
                length--
                slide.parentNode.removeChild(slide)
                checkStatus()
            }

            if (srcsetSupport && slide.dataset.srcset) {
                image.srcset = [slide.dataset.src, ' 1x, ', slide.dataset.srcset].join('')
            } else {
                image.src = slide.dataset.srcset
            }
        }
    })
}

function attach() {
    attached = true

    if (loaded) {
        checkStatus()
    }
}

function detach() {
    attached = false
    if (swiper) {
        swiper.destroy()
        swiper = null
    }
}

enquire.register(Site.screenSize.medium, {
    setup: setup,
    match: attach,
    unmatch: detach,
    deferSetup: true,
})
