import enquire from 'enquire.js'
import Swiper from 'swiper'

var $section = $('.section-timeline')
var $group1 = $section.find('header .photo-wrapper .swiper-wrapper')
var $group2 = $section.find('.content .photo-wrapper .swiper-wrapper')
var $group3 = $section.find('.contacts .photo-wrapper .swiper-wrapper')

var groups = [$group1, $group2, $group3]
var swipers = []

function setup() {
    var imageUrls = $section
        .find('.gallery .swiper-slide')
        .toArray()
        .map(function (element) {
            return $(element).data('src')
        })

    imageUrls.forEach(function (url, index) {
        var id = index % 3
        groups[id].append(
            '<div class="swiper-slide"><img src="' + url + '" class="photo swiper-slide"></div>',
        )
    })

    groups.forEach(function ($group) {
        var swiper = new Swiper($group.closest('.swiper-container')[0], {
            preloadImages: false,
            lazyLoading: true,
            slidesPerView: 1,
            lazyLoadingInPrevNext: true,
            lazyLoadingInPrevNextAmount: 5,
            autoplay: 4000,
            autoplayDisableOnInteraction: false,
        })
        swipers.push(swiper)
    })
}

function attach() {
    swipers.forEach(function (swiper) {
        swiper.startAutoplay()
    })
}

function detach() {
    swipers.forEach(function (swiper) {
        swiper.stopAutoplay()
    })
}

enquire.register(Site.screenSize.maxSmall, {
    setup: setup,
    match: attach,
    unmatch: detach,
    deferSetup: true,
})
