import enquire from 'enquire.js'

var scrollTop = 0
var previousScrollTop = 0
var currScrollTop = scrollTop
var $scrollEl = null
var $parallaxElements = $('[data-parallax]')
var parallaxItems = []
var raf = null
var enabled = false
var cachedScrollTop = 0

var $scope = $('.scroll-wrapper')
var scrollContainer = $scope.find('.scroll-container')[0]
// var $scrollbarContainer = $scope.find('.section__scrollbar');
var $scrollbarContainer = $(document.body)

var end = false
var start = false
var top = true
var bottom = false

var height = 0
var scopeHeight = 0

var slideEntering = false

var $sections = $('.section')
var sections = {}
var activeSection = 'home'
var currentNavType = 'home'

var ParallaxItem = function (element, root) {
    this.element = element
    this.$element = $(this.element)
    this.root = root || window
    this.$root = $(root)
    this.active = false
    this.ratio = this.$element.data('parallax') || 0.075
    this.lastOffset = 0
    this.onResize()
    this.baseOffset = null
}

ParallaxItem.prototype = {
    onResize: function () {
        this.baseOffset = null
        this.offsetTop = this.$element.offset().top + this.$element.height() / 2
        this.rootHeight = this.$root.height()
        this.rootHeightHalf = this.rootHeight / 2
        this.tresholdTop = this.offsetTop - this.rootHeight
        this.tresholdBottom = this.offsetTop + this.rootHeight

        var scrollTop = getScrollTop()

        if (scrollTop < this.tresholdTop) {
            this.render(this.tresholdTop)
        }

        if (scrollTop > this.tresholdBottom) {
            this.render(this.tresholdBottom)
        }
    },

    render: function (scrollTop) {
        if (scrollTop >= this.tresholdTop && scrollTop <= this.tresholdBottom) {
            var offset = (this.offsetTop - scrollTop - this.rootHeightHalf) * this.ratio
            if (this.baseOffset === null) {
                this.baseOffset = offset
            }
            if (Math.abs(offset - this.lastOffset) > 0.05) {
                this.element.style[support.transform] =
                    'translateY(' + -offset + 'px) translateZ(0)'
                this.lastOffset = offset
            }
        }
    },

    reset: function () {
        this.element.style[support.transform] =
            'translateY(' + -this.baseOffset + 'px) translateZ(0)'
    },
}

$parallaxElements.each(function () {
    parallaxItems.push(new ParallaxItem(this, window))
})

function getScrollTop() {
    //return $scrollbarContainer.scrollTop();
    return window.pageYOffset || document.documentElement.scrollTop
}

function resize() {
    height = $(scrollContainer).height()
    scopeHeight = $scope.height()
    $scrollEl.css('height', height)

    sections = {}
    $sections.each(function () {
        sections[this.id] = {
            id: this.id,
            offset: $(this).position().top,
            element: this,
            height: $(this).height(),
            nav: $(this).data('nav'),
        }
    })
}

function render() {
    if (enabled) {
        var diff = scrollTop - currScrollTop
        var direction = diff > 0 ? 'down' : 'up'
        if (Math.abs(diff) > 0.8) {
            end = false
            currScrollTop += diff * 0.075 // 0.075
            scrollContainer.style[support.transform] =
                'translateY(' + -currScrollTop + 'px) translateZ(0)'
            parallaxItems.forEach(function (item) {
                item.render(currScrollTop)
            })
            if (!start) {
                start = true
                $scope.trigger('customscrollstart', direction)
            }
        } else {
            if (start && !end) {
                $scope.trigger('customscrollend', direction)
                end = true
                start = false
            }
        }

        // fire scroll top sooner
        if (scrollTop === 0 && currScrollTop < 100 && !top) {
            $scope.trigger('customscrolltop', direction)
            top = true
        }

        if (scrollTop > 100 && top) {
            top = false
        }

        // fire scroll bottom sooner
        if (
            height - scopeHeight - scrollTop < 5 &&
            height - scopeHeight - currScrollTop < 100 &&
            !bottom
        ) {
            $scope.trigger('customscrollbottom', direction)
            bottom = true
        }

        if (height - scopeHeight - scrollTop > 100 && bottom) {
            bottom = false
        }

        raf = requestAnimationFrame(render)
    }
}

function attach() {
    $scrollEl = $('<div />').css('width', 1)

    $(window).on('scroll.fake-scroll', function () {
        scrollTop = getScrollTop()

        // don't trigger after deactivation
        if (Math.abs(scrollTop - previousScrollTop) < height * 0.8) {
            $(document).trigger('customscroll', scrollTop < previousScrollTop ? 'up' : 'down')
        }

        previousScrollTop = scrollTop
    })

    $(window).on('resize.fake-scroll orientationchange.fake-scroll', resize)

    $scope.addClass('scroll--active')

    // disabled scrolling
    // $(document).on('disable.scrolling', disable);
    // $(document).on('enable.scrolling', enable);

    resize()

    $scrollEl.appendTo($scrollbarContainer).hide()

    $(document).on('click.fake-scroll', 'nav a', function () {
        var id = $(this).attr('href').replace('#', '')
        if (id && id in sections) {
            window.scrollTo(0, sections[id].offset)
        }
    })

    //raf = requestAnimationFrame(render);

    activateScroll()
}

function disable() {
    if (raf) {
        cancelAnimationFrame(raf)
    }
    cachedScrollTop = getScrollTop()
    enabled = false
}

function enable() {
    window.scrollTo(0, cachedScrollTop)
    scrollTop = cachedScrollTop
    currScrollTop = cachedScrollTop
    enabled = true
    raf = requestAnimationFrame(render)
}

function detach() {
    deactivateScroll()

    if (raf) {
        cancelAnimationFrame(raf)
    }
    // $(document).off('.fake-scroll');
    $(window).off('.fake-scroll')
    $scope.removeClass('scroll--active')
    scrollContainer.style[support.transform] = 'translateY(0)'
    $scrollEl.detach()
    $(document).on('.fake-scroll')

    // disabled scrolling
    // $(document).off('disable.scrolling', disable);
    // $(document).off('enable.scrolling', enable);
}

function resetScroll() {
    enabled = false
    cachedScrollTop = 0
    scrollTop = 0
    currScrollTop = 0
    bottom = false
    top = true
    scrollContainer.style[support.transform] = 'translateY(0)'
    parallaxItems.forEach(function (item) {
        item.reset()
    })
}

function activateScroll() {
    $scrollEl.show()
    enable()
}

function deactivateScroll() {
    disable()
    $scrollEl.hide()
}

$(document).on('slidesactivated', function () {
    parallaxItems.forEach(function (item) {
        item.onResize()
    })

    $scrollbarContainer.scrollTop(0)

    resize()
})

// $(document)
//     .on('slideenter', function(event) {
//         if (event.target.getAttribute('data-id') === 'concept') {
//             setTimeout(activateScroll, 400);
//         }
//     })
//     .on('slideafterenter', function(event) {
//         // if (event.target.getAttribute('data-id') === 'concept') {
//         //     activateScroll();
//         // }
//     })
//     .on('slideleave', function(event) {
//         if (event.target.getAttribute('data-id') === 'concept') {
//             deactivateScroll();
//         }
//     })
//     .on('slideafterleave', function(event) {
//         start = false;
//         if (event.target.getAttribute('data-id') === 'concept') {
//             resetScroll();
//         }
//     });

enquire.register('screen and (min-width: 75em)', {
    match: attach,
    unmatch: detach,
})

// $(window).on('load', function() {
// });
