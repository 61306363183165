import enquire from 'enquire.js'

window.$window = $(window)
window.$body = $(document.body)
window.Site = { Form: {} }
window.OLD_IE = window.OLD_IE || false

Site.screenSize = {
    small: 'screen and (min-width: 20em)',
    maxSmall: 'screen and (max-width: 47.9375em)',
    medium: 'screen and (min-width: 48em)',
    maxMedium: 'screen and (max-width:63.9375em)',
    large: 'screen and (min-width:64em)',
    maxLarge: 'screen and (max-width: 74.9375em)',
    xlarge: 'screen and (min-width: 75em)',
}

Site.viewport = {
    small: window.matchMedia(Site.screenSize.small).matches,
    medium: window.matchMedia(Site.screenSize.medium).matches,
    normal: window.matchMedia(Site.screenSize.normal).matches,
    large: window.matchMedia(Site.screenSize.large).matches,
    xlarge: window.matchMedia(Site.screenSize.xLarge).matches,
}

Site.setViewports = function () {
    Site.viewport = {
        small:
            window.matchMedia(Site.screenSize.small).matches &&
            window.matchMedia(Site.screenSize.maxSmall).matches,
        medium:
            window.matchMedia(Site.screenSize.medium).matches &&
            window.matchMedia(Site.screenSize.maxMedium).matches,
        normal:
            window.matchMedia(Site.screenSize.normal).matches &&
            window.matchMedia(Site.screenSize.maxNormal).matches,
        large: window.matchMedia(Site.screenSize.large).matches,
    }
}
Site.setViewports()
$window.on('viewportChange', Site.setViewports)

$.substitute = function (str, sub) {
    return str.replace(/\{(.+?)\}/g, function ($0, $1) {
        return $1 in sub ? sub[$1] : $0
    })
}

var maxMedium = false
var maxSmall = false

enquire.register(Site.screenSize.maxMedium, {
    match: function () {
        maxMedium = true
    },
    unmatch: function () {
        maxMedium = false
    },
})

enquire.register(Site.screenSize.maxSmall, {
    match: function () {
        maxSmall = true
    },
    unmatch: function () {
        maxSmall = false
    },
})
