var $viewport = $('meta[name=viewport]')
$viewport.data('fixed', false)
var defaultValue = $viewport.attr('content')
var fixedValue = 'width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1'
var $body = $(document.body)

function disable() {
    if (!$viewport.data('fixed')) {
        $body.addClass('disable-scrolling')
        $viewport.data('fixed', true)
        $viewport.attr('content', fixedValue)
    }
}

function enable() {
    $body.removeClass('disable-scrolling')
    $viewport.attr('content', defaultValue)
    $viewport.data('fixed', false)
}

$(document).on({
    'disable.scrolling': disable,
    'enable.scrolling': enable,
})
