import enquire from 'enquire.js'

var items = document.querySelectorAll('[data-parallax]')
var raf = null
var scrollOffset = 0
var parallaxItems = []

var ParallaxItem = function (element, root) {
    this.element = element
    this.$element = $(this.element)
    this.root = root || window
    this.$root = $(root)
    this.active = false
    this.ratio = this.$element.data('parallax') || 0.075
    this.lastOffset = 0
    this.onResize()
    this.baseOffset = null
}

ParallaxItem.prototype = {
    onResize: function () {
        this.baseOffset = null
        this.offsetTop = this.$element.offset().top + this.$element.height() / 2
        this.rootHeight = this.$root.height()
        this.rootHeightHalf = this.rootHeight / 2
        this.tresholdTop = this.offsetTop - this.rootHeight
        this.tresholdBottom = this.offsetTop + this.rootHeight

        var scrollTop = getScrollTop()

        if (scrollTop < this.tresholdTop) {
            this.render(this.tresholdTop)
        }

        if (scrollTop > this.tresholdBottom) {
            this.render(this.tresholdBottom)
        }
    },

    render: function (scrollTop) {
        if (scrollTop >= this.tresholdTop && scrollTop <= this.tresholdBottom) {
            var offset = (this.offsetTop - scrollTop - this.rootHeightHalf) * this.ratio
            if (this.baseOffset === null) {
                this.baseOffset = offset
            }
            if (Math.abs(offset - this.lastOffset) > 0.05) {
                this.element.style[support.transform] =
                    'translateY(' + -offset + 'px) translateZ(0)'
                this.lastOffset = offset
            }
        }
    },

    reset: function () {
        this.element.style[support.transform] =
            'translateY(' + -this.baseOffset + 'px) translateZ(0)'
    },
}

function resize() {}

function getScrollTop() {
    return window.pageYOffset || document.documentElement.scrollTop
}

function render() {
    parallaxItems.forEach(function (item) {
        item.render(scrollOffset)
    })

    raf = requestAnimationFrame(render)
}

function attach() {
    $(window).on('scroll.parallax-mobile', function () {
        scrollOffset = getScrollTop()
    })

    raf = requestAnimationFrame(render)
}

function detach() {
    $(window).off('scroll.parallax-mobile')
    if (raf) {
        cancelAnimationFrame(raf)
    }
}

function setup() {
    var $parallaxElements = $('[data-parallax]')

    $parallaxElements.each(function () {
        parallaxItems.push(new ParallaxItem(this, window))
    })
}

enquire.register(Site.screenSize.maxMedium, {
    setup: setup,
    match: attach,
    unmatch: detach,
    deferSetup: true,
})
