import enquire from 'enquire.js'

var activeClass = 'site-nav--active'
var $nav = $('.site-nav')
var $body = $(document.body)
var $toggle = $('.nav-toggle')
var $document = $(document)

var show = function () {
    $document.trigger('disable.scrolling')
    $nav.css('display', 'flex').width()
    $body.addClass(activeClass)
    $toggle.addClass('nav-toggle--active')
}

var hide = function () {
    $body.removeClass(activeClass)
    $toggle.removeClass('nav-toggle--active')
    setTimeout(function () {
        $nav.css('display', 'none')
    }, 200)
    $document.trigger('enable.scrolling')
}

var attach = function () {
    $(document).on('toggle.navigation', function () {
        if ($body.hasClass(activeClass)) {
            hide()
        } else {
            show()
        }
    })

    $nav.on('click.navigation', 'a', function () {
        hide()
    })
}

var detach = function () {
    $document.off('.navigation')
    $body.removeClass(activeClass)
    $document.trigger('enable.scrolling')
    $toggle.removeClass('nav-toggle--active')
    $nav.off('.navigation')
}

enquire.register(Site.screenSize.maxLarge, {
    match: attach,
    unmatch: detach,
})
