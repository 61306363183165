import enquire from 'enquire.js'

var $nav = $('.site-nav')
var $pageWrapper = $('.page-wrapper')
var offset = 0
var $body = $(document.body)
var inside = false
var timer = null

var resize = function () {
    $nav.css({
        left: $pageWrapper.offset().left,
    })
    offset = $(window).height() / 2
}

var refresh = function (force) {
    var bigger = window.pageYOffset > offset

    if ((bigger && inside && !force) || (!bigger && !inside && !force)) {
        return
    }

    inside = bigger

    if (inside) {
        clearTimeout(timer)
        $nav.show().width()
        $body.addClass('site-nav--inside')
    } else {
        $body.removeClass('site-nav--inside')
        timer = setTimeout(function () {
            $nav.hide()
        }, 400)
    }

    // $body.toggleClass('site-nav--inside', bigger);
    // inside = bigger;
}

var attach = function () {
    $(window).on('resize.sideNav orientationchange.siteNav', resize)

    $(window).on('scroll.sideNav', refresh)

    refresh(true)
}

var detach = function () {
    $(window).off('.sideNav')
    $nav.removeAttr('style')
    $body.removeClass('site-nav--inside')
}

resize()

enquire.register(Site.screenSize.xlarge, {
    setup: function () {
        resize()
    },
    match: attach,
    unmatch: detach,
})
