import enquire from 'enquire.js'

var activeClass = 'logo--inline'
var active = false
var offset = 20

var attach = function () {
    var $logo = $('.logo')
    $(window).on('scroll.logo', function () {
        if (window.pageYOffset > offset && active) {
            return
        }

        active = window.pageYOffset > offset
        $logo.toggleClass('logo--inline', active)
    })
}

var detach = function () {
    $(window).off('.logo')
}

enquire.register(Site.screenSize.maxLarge, {
    match: attach,
    unmatch: detach,
})
