$(document).on('click', '[data-trigger]', function (event) {
    var $target = $(this)

    var action = $target.data('trigger')

    if (action) {
        event.preventDefault()
        $target.trigger(action)
    }
})
